import React, { useState , useEffect} from 'react';
import { PlasmicIndex } from "../components/plasmic/gopanda/PlasmicIndex";
import firebase ,{database,firebaseAuth}from "../components/firebase";
// import firebase from "gatsby-plugin-firebase"
import CustomerChat from "../components/fb";
import { Header,Label,Icon} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Helmet } from "react-helmet"




// Use PlasmicIndex to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicIndex are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, PlasmicIndex is wrapped by your project's global
  // variant context providers. These wrappers may be moved to
  // Next.js Custom App component
  // (https://nextjs.org/docs/advanced-features/custom-app).



function Index() {
  // //「請加入新會員」的顯示狀態
  // const [loginState, setloginResults] = useState(false)
  // // 「新會員資料表格」的顯示狀態
  // const [newAccountShow, setnewAccountShow] = useState(false)
  // //  「登入表格」的顯示狀態
  // const [loginFormShow, setloginFormShow] = useState(false)
  // //  「儀錶板」的顯示狀態
  // const [dashBoardShow, setdashBoardShow] = useState(false)
  

  //firebase.auth().signOut()
  //測試階段，先都logout
  // useEffect(() => {
  //   firebase.auth().signOut()
  // });
  
  //驗證有沒有開驗證信用if(user.emailVerified===true)

  //下面這兩段就是要處理每個欄位的輸入
  const [allValues, setAllValues] = useState({
    loginState:false,
    newAccountShow:false,
    loginFormShow:false,
    dashBoardShow:false,
    userCheckStatus:false     //有問題，要試一下，是不是多了變數


  });

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
    //console.log(allValues)
  }
  
  //const database=firebase.database()
 

  firebaseAuth.onAuthStateChanged((user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
         console.log(user)
         if(user.emailVerified===true){
             
            if(allValues.userCheckStatus===false){


              database.child('/users/'+user.uid).on('value', (snapshot) => {
                console.log(1)
                if(snapshot.val()!=null){
                      let userInfo=snapshot.val()
                      console.log(userInfo)
                      setAllValues({ ...allValues, 
                        'welcomeMessage': '歡迎你 '+userInfo['chName'],
                        'loginState': true,
                        'loginFormShow':true,
                        'dashBoardShow':true,
                        'userCheckStatus':true,
                        'userInfo':userInfo,
                        'userUID': user.uid
                      })
                }
              });

                  
            }  
            console.log(allValues)
         }

         
      // ...
    } 
  });

  const applyNewAccount =(e) => {
    //console.log('123')
    setAllValues({ ...allValues, 
      ['newAccountShow']:!allValues.newAccountShow,
     })
  }



  
  return (
    <>
       <Helmet>
             <title>Gopanda 購幫達</title>
                
       </Helmet>
       <PlasmicIndex 

            howToProcess={{
              render:function MyComponent() {
                return(
                  
                      <a href="/process" target="_blank" >  
                          <Header as='h3'  color='white' textAlign='center'>
                                  <div style={{ color: 'white'}}>如何寄送</div>
                                  <div style={{ color: 'white'}}>手續請看這</div>
                          </Header>
                          
                      </a>
                )
              }
            }}

            howToPrice={{
              render:function MyComponent() {
                return(
                  
                      <a href="/price" target="_blank" >  
                          <Header as='h3'  color='white' textAlign='center'>
                                  <div style={{ color: 'white'}}>如何計費</div>
                                  <div style={{ color: 'white'}}>價格請看這</div>
                          </Header>
                          
                      </a>
              )}
            }}

            contract={{
              render:function MyComponent() {
                return(
                  
                      <a href="/contract" target="_blank" >  
                          <Header as='h3'  color='white' textAlign='center'>
                                  <div style={{ color: 'white'}}>代運合約</div>
                                  <div style={{ color: 'white'}}>細節請看這</div>
                          </Header>
                          
                      </a>
              )}
            }}

           notAllowToDelivery={{
              render:function MyComponent() {
                return(
                  
                      <a href="/notAllow" target="_blank" >  
                          <Header as='h3'  color='white' textAlign='center'>
                                  <div style={{ color: 'white'}}>無法代運</div>
                                  <div style={{ color: 'white'}}>品項請看這</div>
                          </Header>
                          
                      </a>
              )}
           }}

           joinMember={{
              successfulLogin: allValues.loginState,
              onClick: applyNewAccount,
              welcome:allValues.welcomeMessage
           }}

           joinMemberPC={{
            successfulLogin: allValues.loginState,
            onClick: applyNewAccount,
            welcome:allValues.welcomeMessage
         }}
          
           newMember={{
              disappear: !allValues.newAccountShow
             
           }}
           
           loginForm={{
              disappear:  allValues.loginFormShow,
           }}

           
           dashBoard={{
              disappear:  !allValues.dashBoardShow,
              userInfo: allValues.userInfo,
              userUID:  allValues.userUID
           }}

           facebook={{
            render:function MyComponent() {
              return(
                
                    <a href="https://www.facebook.com/Gopanda.tw/" target="_blank" >  
                        
                                <Icon name='facebook square' size='massive' />                    
                        
                        
                    </a>
              )
            }
          }}

         />
         <CustomerChat/>
         
         
         
    </>
   )
}

export default Index;
