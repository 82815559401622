// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Gift2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 32 32"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M16.895 2.719a2.96 2.96 0 00-1.17.2c-1.63.638-2.25 2.294-2.573 3.61-.077.316-.034.313-.08.598-.227-.179-.192-.205-.463-.385-1.129-.748-2.709-1.546-4.338-.91a3.015 3.015 0 00-1.7 3.887c.127.328.318.611.538.863l-4.832 1.889.366.931 2.183 5.588L6 18.531V29h22V15H15.04l12.14-4.744-2.55-6.522-4.83 1.887a2.938 2.938 0 00-.189-.998 3.018 3.018 0 00-2.716-1.904zm-.047 1.992c.39.008.746.246.9.64a.985.985 0 01-.568 1.295l-2.149.84c.037-.229.004-.231.065-.48.259-1.058.772-1.994 1.355-2.223.132-.051.266-.075.397-.072zm6.648 1.615l1.092 2.795-8.381 3.275-1.094-2.794.453-.178 2.342-.914 5.588-2.184zM9.51 7.625c.573.027 1.312.331 1.994.783.215.142.192.167.375.31-.866.34-1.556.609-2.15.84a.986.986 0 01-1.297-.568A.985.985 0 019 7.695c.146-.057.319-.08.51-.07zm3.74 2.705l1.094 2.795L5.96 16.4l-1.092-2.795 5.586-2.183 2.344-.916.451-.176zM9.92 17H16v10H8v-9.25L9.92 17zM18 17h8v10h-8V17z"
        }
      ></path>
    </svg>
  );
}

export default Gift2Icon;
/* prettier-ignore-end */
