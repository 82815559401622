// extracted by mini-css-extract-plugin
export var root = "PlasmicDashBoard-module--root--1-nNe";
export var title = "PlasmicDashBoard-module--title--2HOaC";
export var freeBox__em9LG = "PlasmicDashBoard-module--freeBox__em9LG--19yLU";
export var text__beK4 = "PlasmicDashBoard-module--text__beK4--EMgCg";
export var freeBox__nrndh = "PlasmicDashBoard-module--freeBox__nrndh--mlvyC";
export var freeBox___9Ryqs = "PlasmicDashBoard-module--freeBox___9Ryqs--153ZW";
export var text__fqIgM = "PlasmicDashBoard-module--text__fqIgM--32fnn";
export var freeBox__kt7R = "PlasmicDashBoard-module--freeBox__kt7R--RIt2Y";
export var text__tx9Ob = "PlasmicDashBoard-module--text__tx9Ob--1R1Wm";
export var 收件人姓名 = "PlasmicDashBoard-module--收件人姓名--2xvHi";
export var freeBox___92WRt = "PlasmicDashBoard-module--freeBox___92WRt--12TgW";
export var text__op0St = "PlasmicDashBoard-module--text__op0St--32n-R";
export var nameCopy = "PlasmicDashBoard-module--nameCopy--1vfT7";
export var nameCopyAlert = "PlasmicDashBoard-module--nameCopyAlert--wT7kP";
export var nameCol = "PlasmicDashBoard-module--nameCol--SVybu";
export var 收件人電話 = "PlasmicDashBoard-module--收件人電話--1cmS4";
export var freeBox__nVuuL = "PlasmicDashBoard-module--freeBox__nVuuL--1k2Lu";
export var text__ceTpu = "PlasmicDashBoard-module--text__ceTpu--lSpBn";
export var phoneCopy = "PlasmicDashBoard-module--phoneCopy--EnIjn";
export var phoneCopyAlert = "PlasmicDashBoard-module--phoneCopyAlert--1bMg9";
export var phoneCol = "PlasmicDashBoard-module--phoneCol--2gXLF";
export var 收件人地址 = "PlasmicDashBoard-module--收件人地址--KZ3HW";
export var freeBox__po8V9 = "PlasmicDashBoard-module--freeBox__po8V9--1wY68";
export var text__va5Fa = "PlasmicDashBoard-module--text__va5Fa--ZZKvV";
export var addressCopy = "PlasmicDashBoard-module--addressCopy--1KtDD";
export var addressCopyAlert = "PlasmicDashBoard-module--addressCopyAlert--SRaO-";
export var addressCopyCol = "PlasmicDashBoard-module--addressCopyCol--gR9z8";
export var 收件人郵遞區號 = "PlasmicDashBoard-module--收件人郵遞區號--MneWP";
export var freeBox__cN5Lw = "PlasmicDashBoard-module--freeBox__cN5Lw--LUVIz";
export var text__bwSZd = "PlasmicDashBoard-module--text__bwSZd--25HaB";
export var zipCodeCopy = "PlasmicDashBoard-module--zipCodeCopy--3OqXU";
export var zipCodeCopyAlert = "PlasmicDashBoard-module--zipCodeCopyAlert--177BJ";
export var zipCodeCopyCol = "PlasmicDashBoard-module--zipCodeCopyCol--1jc5B";
export var 收件人城市 = "PlasmicDashBoard-module--收件人城市--3iJJO";
export var freeBox__tBbqf = "PlasmicDashBoard-module--freeBox__tBbqf--1WN_M";
export var text__yOp9W = "PlasmicDashBoard-module--text__yOp9W--3byA4";
export var cityCopy = "PlasmicDashBoard-module--cityCopy--3g93P";
export var cityCopyAlert = "PlasmicDashBoard-module--cityCopyAlert--3Jhnh";
export var cityCopyCol = "PlasmicDashBoard-module--cityCopyCol--1NnqE";
export var 收件人區域 = "PlasmicDashBoard-module--收件人區域--3nxxr";
export var freeBox__nyHlu = "PlasmicDashBoard-module--freeBox__nyHlu--b2HXv";
export var text___9ZImo = "PlasmicDashBoard-module--text___9ZImo--1VTbe";
export var areaCopy = "PlasmicDashBoard-module--areaCopy--OcKOG";
export var areaCopyAlert = "PlasmicDashBoard-module--areaCopyAlert--3NknO";
export var areaCopyCol = "PlasmicDashBoard-module--areaCopyCol--1MYWv";
export var 內容清單Mobile = "PlasmicDashBoard-module--內容清單Mobile--2qzA-";
export var freeBox___2GXfY = "PlasmicDashBoard-module--freeBox___2GXfY--2B32G";
export var text__yub3P = "PlasmicDashBoard-module--text__yub3P--14ujc";
export var freeBox__av6Xe = "PlasmicDashBoard-module--freeBox__av6Xe--3X5U2";
export var freeBox__fc7Fn = "PlasmicDashBoard-module--freeBox__fc7Fn--3mRQ5";
export var freeBox__j1COu = "PlasmicDashBoard-module--freeBox__j1COu--2RKiW";
export var text__lqz5Y = "PlasmicDashBoard-module--text__lqz5Y--3suNk";
export var freeBox__pAdws = "PlasmicDashBoard-module--freeBox__pAdws--TOhjD";
export var text__khe37 = "PlasmicDashBoard-module--text__khe37--PXVoL";
export var freeBox__btfxR = "PlasmicDashBoard-module--freeBox__btfxR--2PC1l";
export var freeBox__kw6Pm = "PlasmicDashBoard-module--freeBox__kw6Pm--1fxgj";
export var text__f1VTa = "PlasmicDashBoard-module--text__f1VTa--2wAz_";
export var freeBox__mQFca = "PlasmicDashBoard-module--freeBox__mQFca--1oY9F";
export var text__vMYf4 = "PlasmicDashBoard-module--text__vMYf4--1moOM";
export var freeBox__qZdtl = "PlasmicDashBoard-module--freeBox__qZdtl--2SjaX";
export var freeBox__mJRnc = "PlasmicDashBoard-module--freeBox__mJRnc--3MYm3";
export var text__kUpSz = "PlasmicDashBoard-module--text__kUpSz--3Omeg";
export var freeBox__tShar = "PlasmicDashBoard-module--freeBox__tShar--A6oHd";
export var text__pZy = "PlasmicDashBoard-module--text__pZy--wCRn7";
export var text__amGll = "PlasmicDashBoard-module--text__amGll--3K3RB";
export var freeBox__jMbmm = "PlasmicDashBoard-module--freeBox__jMbmm--39Xl9";
export var text__kHIyu = "PlasmicDashBoard-module--text__kHIyu--3Cx4X";
export var storeName = "PlasmicDashBoard-module--storeName--1dFiL";
export var freeBox__hCdeY = "PlasmicDashBoard-module--freeBox__hCdeY--3DFrD";
export var text__rgKvz = "PlasmicDashBoard-module--text__rgKvz--3n0ty";
export var orderNumber = "PlasmicDashBoard-module--orderNumber--2lljK";
export var freeBox__qUzyT = "PlasmicDashBoard-module--freeBox__qUzyT--2VOCz";
export var freeBox__gFt2C = "PlasmicDashBoard-module--freeBox__gFt2C--2Ohog";
export var text__edbQc = "PlasmicDashBoard-module--text__edbQc--3z7PX";
export var freeBox__kiDv = "PlasmicDashBoard-module--freeBox__kiDv--2Zgdn";
export var text__tkFr7 = "PlasmicDashBoard-module--text__tkFr7--2ogh2";
export var addShippingItem = "PlasmicDashBoard-module--addShippingItem--rXZqZ";
export var svg__rcH48 = "PlasmicDashBoard-module--svg__rcH48--2Yv47";
export var cutShippingItem = "PlasmicDashBoard-module--cutShippingItem--1z4BK";
export var svg__yQjoq = "PlasmicDashBoard-module--svg__yQjoq--LVgJb";
export var 寄送內容 = "PlasmicDashBoard-module--寄送內容--3VoXM";
export var 內容表頭 = "PlasmicDashBoard-module--內容表頭--2enMR";
export var text___4Arbn = "PlasmicDashBoard-module--text___4Arbn--1D3nf";
export var text__tNq0B = "PlasmicDashBoard-module--text__tNq0B--2FH4K";
export var text__fBm3S = "PlasmicDashBoard-module--text__fBm3S--2O3QE";
export var text__upyNs = "PlasmicDashBoard-module--text__upyNs--2vD0Q";
export var shippingContentArea = "PlasmicDashBoard-module--shippingContentArea--1_r0b";
export var freeBox__ixlAb = "PlasmicDashBoard-module--freeBox__ixlAb--vyBxy";
export var uploadShippingContent = "PlasmicDashBoard-module--uploadShippingContent--2_wNF";
export var freeBox__nI3HS = "PlasmicDashBoard-module--freeBox__nI3HS--2AK7h";
export var uploadContentCheckAlert = "PlasmicDashBoard-module--uploadContentCheckAlert--3f2eV";
export var 內容清單Pc = "PlasmicDashBoard-module--內容清單Pc--1B1Mp";
export var freeBox__qzoLe = "PlasmicDashBoard-module--freeBox__qzoLe--6UIAH";
export var text___1WHpB = "PlasmicDashBoard-module--text___1WHpB--3x6te";
export var freeBox__wSpNl = "PlasmicDashBoard-module--freeBox__wSpNl--2Sojw";
export var freeBox__eqxrD = "PlasmicDashBoard-module--freeBox__eqxrD--3v84_";
export var freeBox__stVb8 = "PlasmicDashBoard-module--freeBox__stVb8--mhkRK";
export var text__uss8N = "PlasmicDashBoard-module--text__uss8N--3Kkcd";
export var freeBox__mrBp = "PlasmicDashBoard-module--freeBox__mrBp--yojoY";
export var text__yuIfU = "PlasmicDashBoard-module--text__yuIfU--148W7";
export var freeBox__gUsH = "PlasmicDashBoard-module--freeBox__gUsH--1Eki0";
export var freeBox__xUyam = "PlasmicDashBoard-module--freeBox__xUyam--1JpGT";
export var text__zLRpZ = "PlasmicDashBoard-module--text__zLRpZ--1dwAz";
export var freeBox__mwHlr = "PlasmicDashBoard-module--freeBox__mwHlr--3Z336";
export var text__lDvIb = "PlasmicDashBoard-module--text__lDvIb--3n9UZ";
export var freeBox__rw6A2 = "PlasmicDashBoard-module--freeBox__rw6A2--17yVy";
export var freeBox__w7BxB = "PlasmicDashBoard-module--freeBox__w7BxB--1-yBZ";
export var text__lCntj = "PlasmicDashBoard-module--text__lCntj--1NrlZ";
export var freeBox__nK30L = "PlasmicDashBoard-module--freeBox__nK30L--1_Fk6";
export var text__bmMb = "PlasmicDashBoard-module--text__bmMb--176ha";
export var text___4ZVd1 = "PlasmicDashBoard-module--text___4ZVd1--17I3k";
export var freeBox__pndFl = "PlasmicDashBoard-module--freeBox__pndFl--1uf3J";
export var text__q30Or = "PlasmicDashBoard-module--text__q30Or--3eiou";
export var storeNameDesktop = "PlasmicDashBoard-module--storeNameDesktop--3Ye7R";
export var freeBox__nvvq = "PlasmicDashBoard-module--freeBox__nvvq--2RWuw";
export var text__wsmwz = "PlasmicDashBoard-module--text__wsmwz--3ILdi";
export var orderNumberDesktop = "PlasmicDashBoard-module--orderNumberDesktop--NENec";
export var freeBox__mYKeM = "PlasmicDashBoard-module--freeBox__mYKeM--1qkIl";
export var freeBox___9W7Q = "PlasmicDashBoard-module--freeBox___9W7Q--2vDbj";
export var text__qMjrt = "PlasmicDashBoard-module--text__qMjrt--3koNy";
export var freeBox__jckRr = "PlasmicDashBoard-module--freeBox__jckRr--2mYPj";
export var text__rwbgl = "PlasmicDashBoard-module--text__rwbgl--3HIuY";
export var addShippingItemDesktop = "PlasmicDashBoard-module--addShippingItemDesktop--3o5p_";
export var svg___0CihU = "PlasmicDashBoard-module--svg___0CihU--3KhJL";
export var cutShippingItemDesktop = "PlasmicDashBoard-module--cutShippingItemDesktop--2V0mk";
export var svg__s2U9L = "PlasmicDashBoard-module--svg__s2U9L--2Rfyj";
export var 寄送內容2 = "PlasmicDashBoard-module--寄送內容2--3jlzB";
export var 內容表頭2 = "PlasmicDashBoard-module--內容表頭2--8D9pT";
export var text__kAHhO = "PlasmicDashBoard-module--text__kAHhO--24cDx";
export var text___8OUoG = "PlasmicDashBoard-module--text___8OUoG--WqLHw";
export var text__fl7Wn = "PlasmicDashBoard-module--text__fl7Wn--Q5VJS";
export var text__q1Nty = "PlasmicDashBoard-module--text__q1Nty--1UX74";
export var shippingContentAreaDesktop = "PlasmicDashBoard-module--shippingContentAreaDesktop--5w8iu";
export var freeBox__lnKnn = "PlasmicDashBoard-module--freeBox__lnKnn--1vxnx";
export var uploadShippingContentDesktop = "PlasmicDashBoard-module--uploadShippingContentDesktop--XbQpS";
export var freeBox___50Al = "PlasmicDashBoard-module--freeBox___50Al--1wVBT";
export var uploadContentCheckAlertDesktop = "PlasmicDashBoard-module--uploadContentCheckAlertDesktop--3bEF4";
export var 待收貨物列表Mobile = "PlasmicDashBoard-module--待收貨物列表Mobile--3p9h6";
export var freeBox__cJc9O = "PlasmicDashBoard-module--freeBox__cJc9O--e5BL6";
export var text__gZtQw = "PlasmicDashBoard-module--text__gZtQw--3DTuP";
export var 待收貨物 = "PlasmicDashBoard-module--待收貨物--31CqM";
export var waitList = "PlasmicDashBoard-module--waitList--7PFk7";
export var 待收貨物列表Pc = "PlasmicDashBoard-module--待收貨物列表Pc--8MAZC";
export var freeBox__px1GE = "PlasmicDashBoard-module--freeBox__px1GE--2ZUoD";
export var text__pltv = "PlasmicDashBoard-module--text__pltv--BdZOw";
export var freeBox__mud0X = "PlasmicDashBoard-module--freeBox__mud0X--nBaFS";
export var waitListDesktop = "PlasmicDashBoard-module--waitListDesktop--3-erw";
export var 待結帳訂單列表Mobile = "PlasmicDashBoard-module--待結帳訂單列表Mobile--3lMM_";
export var freeBox__y5776 = "PlasmicDashBoard-module--freeBox__y5776--3nW4E";
export var text__vswSd = "PlasmicDashBoard-module--text__vswSd--2Xij6";
export var paymentList = "PlasmicDashBoard-module--paymentList--1JsNa";
export var 待結帳訂單列表Pc = "PlasmicDashBoard-module--待結帳訂單列表Pc--3kZA0";
export var freeBox___8Ms3Z = "PlasmicDashBoard-module--freeBox___8Ms3Z--1bK0e";
export var text__ihyR = "PlasmicDashBoard-module--text__ihyR--xjmL-";
export var paymentListDesktop = "PlasmicDashBoard-module--paymentListDesktop--1v0rq";
export var 完成付款準備出貨列表Mobile = "PlasmicDashBoard-module--完成付款準備出貨列表Mobile--1y9tc";
export var freeBox__mhvHq = "PlasmicDashBoard-module--freeBox__mhvHq--2_BcR";
export var text__lKbGx = "PlasmicDashBoard-module--text__lKbGx--McuuI";
export var readyList = "PlasmicDashBoard-module--readyList--kt0bP";
export var 完成付款準備出貨列表Pc = "PlasmicDashBoard-module--完成付款準備出貨列表Pc--1UTHS";
export var freeBox__oNrRf = "PlasmicDashBoard-module--freeBox__oNrRf--NT_gL";
export var text__cjT2F = "PlasmicDashBoard-module--text__cjT2F--2RiCb";
export var readyListDesktop = "PlasmicDashBoard-module--readyListDesktop--2cSqX";
export var 已經出貨列表Mobile = "PlasmicDashBoard-module--已經出貨列表Mobile--2zPyD";
export var freeBox__ucltq = "PlasmicDashBoard-module--freeBox__ucltq--OlzOY";
export var text___5Tj8J = "PlasmicDashBoard-module--text___5Tj8J--1uWmh";
export var finishList = "PlasmicDashBoard-module--finishList--q9tcj";
export var 已經出貨列表Pc = "PlasmicDashBoard-module--已經出貨列表Pc--2n54U";
export var freeBox___5NfV = "PlasmicDashBoard-module--freeBox___5NfV--2MadM";
export var text___7PMmn = "PlasmicDashBoard-module--text___7PMmn--sXsyc";
export var finishListDesktop = "PlasmicDashBoard-module--finishListDesktop--qFj-B";
export var freeBox__etDf9 = "PlasmicDashBoard-module--freeBox__etDf9--3qSoG";
export var 更改會員資料 = "PlasmicDashBoard-module--更改會員資料--3tmJm";
export var changeCustomerInfo = "PlasmicDashBoard-module--changeCustomerInfo--2LAhp";
export var text__mAw9C = "PlasmicDashBoard-module--text__mAw9C--1A-bC";