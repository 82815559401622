// extracted by mini-css-extract-plugin
export var root = "PlasmicNewMember-module--root--oKrfL";
export var root__successful = "PlasmicNewMember-module--root__successful--3-sBm";
export var text__bsRB = "PlasmicNewMember-module--text__bsRB--1SCWg";
export var text__successful__bsRB4Dso7 = "PlasmicNewMember-module--text__successful__bsRB4Dso7--14rAS";
export var freeBox__prbjZ = "PlasmicNewMember-module--freeBox__prbjZ--2SSAH";
export var freeBox__successful__prbjZ4Dso7 = "PlasmicNewMember-module--freeBox__successful__prbjZ4Dso7--1KnDK";
export var freeBox__gqss0 = "PlasmicNewMember-module--freeBox__gqss0--3gvFE";
export var freeBox__successful__gqss04Dso7 = "PlasmicNewMember-module--freeBox__successful__gqss04Dso7--3eZf8";
export var text__jFeDb = "PlasmicNewMember-module--text__jFeDb--2_REb";
export var text__o98T = "PlasmicNewMember-module--text__o98T--2z0BT";
export var 帳號 = "PlasmicNewMember-module--帳號--X0xsw";
export var 帳號__successful = "PlasmicNewMember-module--帳號__successful--2pCDY";
export var freeBox__mfHml = "PlasmicNewMember-module--freeBox__mfHml--3v20m";
export var freeBox__successful__mfHml4Dso7 = "PlasmicNewMember-module--freeBox__successful__mfHml4Dso7--1sA0e";
export var text___07E8H = "PlasmicNewMember-module--text___07E8H--27bqt";
export var freeBox__xkAm9 = "PlasmicNewMember-module--freeBox__xkAm9--2RlAS";
export var email = "PlasmicNewMember-module--email--2DAqc";
export var 密碼 = "PlasmicNewMember-module--密碼--1NnOo";
export var 密碼__successful = "PlasmicNewMember-module--密碼__successful--3SSoS";
export var freeBox__uJCog = "PlasmicNewMember-module--freeBox__uJCog--2Fl8-";
export var text__hSNjM = "PlasmicNewMember-module--text__hSNjM--2NEnr";
export var passWord = "PlasmicNewMember-module--passWord--3bK4y";
export var passWordAlert = "PlasmicNewMember-module--passWordAlert--3ferx";
export var text__prxRy = "PlasmicNewMember-module--text__prxRy--cOvkO";
export var passWordCheck = "PlasmicNewMember-module--passWordCheck--2iCeT";
export var passWordCheckAlert = "PlasmicNewMember-module--passWordCheckAlert--3hmyZ";
export var 國別 = "PlasmicNewMember-module--國別--3ltLV";
export var 國別__successful = "PlasmicNewMember-module--國別__successful--1Vpdu";
export var freeBox__mnjZn = "PlasmicNewMember-module--freeBox__mnjZn--3OH8D";
export var text__mesNy = "PlasmicNewMember-module--text__mesNy--3Wj3t";
export var nationSelection = "PlasmicNewMember-module--nationSelection--3xOr5";
export var 收件人 = "PlasmicNewMember-module--收件人--1G41_";
export var 收件人__successful = "PlasmicNewMember-module--收件人__successful--R6NPb";
export var freeBox___9V7Ke = "PlasmicNewMember-module--freeBox___9V7Ke--1-_Ht";
export var text__rB5W2 = "PlasmicNewMember-module--text__rB5W2---kDeI";
export var chName = "PlasmicNewMember-module--chName--39h0o";
export var text__fIqhc = "PlasmicNewMember-module--text__fIqhc--wGVmG";
export var enName = "PlasmicNewMember-module--enName--3cMq9";
export var 電話 = "PlasmicNewMember-module--電話--_e33J";
export var 電話__successful = "PlasmicNewMember-module--電話__successful--2Iv3i";
export var freeBox__lsntG = "PlasmicNewMember-module--freeBox__lsntG--viPPy";
export var text__jKh9Q = "PlasmicNewMember-module--text__jKh9Q--1750j";
export var text__y8Zz = "PlasmicNewMember-module--text__y8Zz--N7Fp9";
export var text__e5Ugl = "PlasmicNewMember-module--text__e5Ugl--2fFUh";
export var freeBox__qiRlp = "PlasmicNewMember-module--freeBox__qiRlp--TBxSW";
export var mobilePhone = "PlasmicNewMember-module--mobilePhone--1t4Mi";
export var mobilePhoneCheck = "PlasmicNewMember-module--mobilePhoneCheck--1wj0c";
export var recaptcha = "PlasmicNewMember-module--recaptcha--2pK-I";
export var recaptchaContainer = "PlasmicNewMember-module--recaptchaContainer--36Wuc";
export var mobilePhoneCheckAlert = "PlasmicNewMember-module--mobilePhoneCheckAlert--3X8xY";
export var text___1JzG = "PlasmicNewMember-module--text___1JzG--5tl1T";
export var freeBox__veYeA = "PlasmicNewMember-module--freeBox__veYeA--3qgUu";
export var phoneCheckNumber = "PlasmicNewMember-module--phoneCheckNumber--2ui6v";
export var sendCheckNumber = "PlasmicNewMember-module--sendCheckNumber--3SkKP";
export var sendCheckNumberAlert = "PlasmicNewMember-module--sendCheckNumberAlert--3ojtm";
export var 郵遞區號 = "PlasmicNewMember-module--郵遞區號--1aqqg";
export var 郵遞區號__successful = "PlasmicNewMember-module--郵遞區號__successful--1iCPJ";
export var freeBox__yjvTz = "PlasmicNewMember-module--freeBox__yjvTz--3C7Ii";
export var text__lleR1 = "PlasmicNewMember-module--text__lleR1--1v6XJ";
export var zipCode = "PlasmicNewMember-module--zipCode--1MzOL";
export var 快遞地址 = "PlasmicNewMember-module--快遞地址--W5JnR";
export var 快遞地址__successful = "PlasmicNewMember-module--快遞地址__successful--2CN7P";
export var freeBox__uimMs = "PlasmicNewMember-module--freeBox__uimMs--2PGmI";
export var text__sij6R = "PlasmicNewMember-module--text__sij6R--yC7KG";
export var address = "PlasmicNewMember-module--address--3JLuU";
export var createAccountCheckAlert = "PlasmicNewMember-module--createAccountCheckAlert--2ypHI";
export var createAccountCheckAlert__successful = "PlasmicNewMember-module--createAccountCheckAlert__successful--1QtTK";
export var 申請新帳戶 = "PlasmicNewMember-module--申請新帳戶--uHS17";
export var 申請新帳戶__successful = "PlasmicNewMember-module--申請新帳戶__successful--3fJvR";
export var freeBox___8Jbbl = "PlasmicNewMember-module--freeBox___8Jbbl--2pLwY";
export var createAccount = "PlasmicNewMember-module--createAccount--2lM0H";