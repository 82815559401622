// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function MinussvgIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 512 512"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M256 0C114.853 0 0 114.833 0 256s114.853 256 256 256c141.167 0 256-114.833 256-256S397.147 0 256 0zm0 472.341c-119.295 0-216.341-97.046-216.341-216.341S136.705 39.659 256 39.659 472.341 136.705 472.341 256 375.295 472.341 256 472.341z"
        }
      ></path>

      <path
        d={
          "M355.148 234.386H156.852c-10.946 0-19.83 8.884-19.83 19.83s8.884 19.83 19.83 19.83h198.296c10.946 0 19.83-8.884 19.83-19.83s-8.884-19.83-19.83-19.83z"
        }
      ></path>
    </svg>
  );
}

export default MinussvgIcon;
/* prettier-ignore-end */
