import React, { useState , useEffect} from 'react';
import { PlasmicLoginForm } from "./plasmic/gopanda/PlasmicLoginForm";
import firebase from "./firebase";
//import firebase from "gatsby-plugin-firebase"


function LoginForm_(props, ref) {
  
  //下面這兩段就是要處理每個欄位的輸入
  const [allValues, setAllValues] = useState({
    accountEmail: '',
    passWord: '',
  });

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
    //console.log(allValues)
  }
  
  //const database=firebase.database()

  const loginHandler = (e)=>{
    //console.log('login')
    firebase.auth().signInWithEmailAndPassword(allValues.accountEmail,allValues.passWord)
    .then((userCredential) => {
      // Signed in
          var user = userCredential.user;
          if(user.emailVerified===true){
              //email驗證過了，那才可以讓客人開始使用
              //setAllValues({ ...allValues, ['disappearStatus']: true })
              //console.log('good3')
          }else{
              setAllValues({ ...allValues, 
                            loginCheckAlert: '請先到註冊信箱點擊認證信喔!!',
                            accountEmail:'',
                            passWord:''
                          })
          }
          
      // ...
    })
    .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode)
            if(errorCode){
                    setAllValues({ ...allValues, 
                      loginCheckAlert: '帳號或是密碼好像錯誤喔!!',
                    })
            }

    });
  }


  const forgetPasswordHandler = (e)=>{
    //console.log('login')
    if(allValues.accountEmail!==''){
      var actionCodeSettings = {
        // After email is verified, the user will be give the ability to go back
        // to the sign-in page.
        url: 'https://gopanda.tw/',
        handleCodeInApp: false
      };
      firebase.auth().sendPasswordResetEmail(allValues.accountEmail,actionCodeSettings)
      .then(() => {
                setAllValues({ ...allValues, 
                  loginCheckAlert: '請到你的信箱開信重設密碼囉!',
                  accountEmail:'',
                  passWord:''
                })
      })
      .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            //console.log(errorCode)
            if(errorCode==='auth/user-not-found'){
                  setAllValues({ ...allValues, 
                    loginCheckAlert: '這個帳號沒有註冊紀錄喔!',
                    accountEmail:'',
                    passWord:''
                  })
            }
      });

    }else{
            setAllValues({ ...allValues, 
              loginCheckAlert: "請先填上帳號，再按一次'忘記密碼'",
              accountEmail:'',
              passWord:''
            })
    }
    
  }

  return <PlasmicLoginForm 
           root={{ ref }} {...props} 

           //disappear= {allValues.disappearStatus}
           accountEmail={{
            name:"accountEmail",
            value: allValues.accountEmail,
            onChange: changeHandler ,
           }}

           passWord={{
            name:"passWord",
            value: allValues.passWord ,
            onChange: changeHandler,
           }}

           loginCheckAlert={ {
            children: allValues.loginCheckAlert 
           }}


           login={{
             name:"login",
             onClick:loginHandler
           }}

           forgetPassword={{
            name:"forgetPassword",
            onClick:forgetPasswordHandler
           }}
           />;
}

const LoginForm = React.forwardRef(LoginForm_);

export default LoginForm;
