// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function FlightIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      data-name={"åå±¤ 2"}
      viewBox={"0 0 230.86 115.9"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",
        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M85.39 68.57c-2.77-.13-5.41-.21-8-.38-4.59-.3-9.18-.58-13.75-1-3.54-.34-7-.91-10.57-1.38a4.34 4.34 0 01-.7-.14 2.07 2.07 0 01-1.67-2.28A2.11 2.11 0 0153 62c2.55.1 5.11.33 7.66.3 7.08-.09 14.15-.27 21.22-.44 1.68 0 3.36-.15 5-.33a2.09 2.09 0 001.29-.8c1.47-2 2.73-4.22 4.3-6.17a27.4 27.4 0 018.73-6.93 29.5 29.5 0 0110.52-3.23c.09-1.42.2-2.81.25-4.2q.66-17.37 1.31-34.75c0-1.08.1-2.16.17-3.24.09-1.32.85-2.16 1.93-2.16s1.88.79 1.94 2.14c.25 5.51.47 11 .69 16.54.34 8.5.67 17 1 25.6 1.27.24 2.52.42 3.74.73a30.32 30.32 0 0118.47 13.24 1.79 1.79 0 01.31.51c.71 2.45 2.46 2.94 4.81 3 7.32.08 14.62.44 21.94.56 3.11.05 6.23-.2 9.34-.34 1.37-.06 2.39.49 2.54 1.46a2.06 2.06 0 01-1.94 2.31c-3.08.47-6.16 1-9.26 1.2-7.41.59-14.82 1-22.23 1.56l-1.12.1c.17 2.48.43 4.86.46 7.24A37.87 37.87 0 01133 104.23c-5.21 4.39-11.13 7.23-18.08 7.09a24.48 24.48 0 01-12.54-3.9A36.27 36.27 0 0189 92.78a37.6 37.6 0 01-4-21.21c.05-.95.22-1.89.39-3zm27.54 4.12V58.8c-1.35.36-2.49.68-3.63 1-4.33 1.05-8.67 2.06-13 3.14-1.63.41-2.14 1.15-2.15 2.84v5.51c0 1.15.35 2.22 1.54 2.57a6.34 6.34 0 002.26 0c2.62-.2 5.24-.46 7.87-.67 2.32-.19 4.66-.33 7.11-.5zm5.08-.06c5.39.44 10.68.89 16 1.29 1.82.13 2.72-.79 2.74-2.64s0-3.68 0-5.51-.56-2.47-2.24-2.9q-4.69-1.2-9.41-2.34c-2.31-.56-4.62-1.09-7.06-1.65z"
        }
      ></path>

      <path
        d={
          "M185.54 92.66a39.78 39.78 0 014.25 3.5 12.85 12.85 0 013.37 12.15 7.6 7.6 0 01-3.7 4.65c-2.86 1.79-6.08 2.33-9.34 2.7a22.47 22.47 0 01-8.68-.44 8.84 8.84 0 01-6.18-7.58c-.49-4.72.83-8 3.71-11.74-4.68 1.1-9.18 2.1-13.65 3.1s-8.88 2.17-13.44 3.28a52.19 52.19 0 002.91-4.56c1.55-3.09 3-6.25 4.33-9.42.28-.65.52-.93 1.23-1 11.22-.56 22.44-1.12 33.65-1.75 9.81-.55 19.61-1.19 29.41-1.79 5-.3 10-.62 15.06-.91 1.41-.08 2.34.64 2.39 1.77s-.72 1.8-2.21 2c-6.59.74-13.18 1.41-19.74 2.31-7.46 1-14.9 2.3-22.34 3.46-.36.09-.7.19-1.03.27zm-6.18 20.2c2.07-.42 4.18-.68 6.18-1.3a5.51 5.51 0 004.25-4.56 9 9 0 00-17.62-3.1c-.77 2.79-.32 5.89 2 7.51 1.55 1.09 3.33 1.21 5.19 1.45zM45.64 92.66c-4-.66-7.8-1.34-11.59-1.89-6.31-.94-12.63-1.85-19-2.68-4.31-.56-8.64-1-13-1.45C.74 86.5 0 85.83 0 84.7s1-1.86 2.38-1.79c2.44.13 4.87.3 7.3.44l22.73 1.36q11.18.66 22.36 1.29 10.53.59 21.07 1.13l5.58.32a45.38 45.38 0 007.71 14.82l-26.76-6.33a50.85 50.85 0 012.89 6.36A11.18 11.18 0 0164 112a8.63 8.63 0 01-6.55 3.69 28.43 28.43 0 01-12.89-1.24c-6-2-8.33-5.7-6.78-12.23a13 13 0 016.53-8.61c.39-.25.69-.53 1.33-.95zm5.93 19.89a17.57 17.57 0 001.92 0c2.24-.24 4.13-1.15 5-3.36a9 9 0 10-17.41-4.07 5.42 5.42 0 003 5.86c2.32 1.35 4.92 1.47 7.49 1.57z"
        }
      ></path>

      <path
        d={
          "M112.93 72.69c-2.45.17-4.79.31-7.12.49-2.63.21-5.25.47-7.87.67a6.34 6.34 0 01-2.26 0c-1.19-.35-1.52-1.42-1.54-2.57v-5.51c0-1.69.52-2.43 2.15-2.84 4.33-1.08 8.67-2.09 13-3.14 1.14-.28 2.28-.6 3.63-1zm5.07-.06V58.88c2.44.56 4.75 1.09 7.06 1.65q4.71 1.14 9.41 2.34c1.68.43 2.23 1.17 2.24 2.9s0 3.67 0 5.51-.92 2.77-2.74 2.64c-5.28-.4-10.57-.85-15.97-1.29zm61.36 40.23c-1.86-.24-3.64-.36-5.2-1.45-2.31-1.62-2.76-4.72-2-7.51a9 9 0 0117.62 3.1 5.51 5.51 0 01-4.25 4.56c-1.99.62-4.1.88-6.17 1.3zm-127.79-.31c-2.59-.1-5.17-.22-7.52-1.58a5.42 5.42 0 01-3-5.86 9 9 0 1117.41 4.07c-.85 2.21-2.74 3.12-5 3.36a17.57 17.57 0 01-1.89.01z"
        }
        fill={"none"}
      ></path>
    </svg>
  );
}

export default FlightIcon;
/* prettier-ignore-end */
