import React, { useState , useEffect ,useRef} from 'react';
import { PlasmicNewMember } from "./plasmic/gopanda/PlasmicNewMember";
import firebase,{database} from './firebase'
//import firebase from "gatsby-plugin-firebase"
import { Modal,Form,Radio,Loader} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'


function NewMember_(props, ref) {

  //做一個整體的state
  const [allValues, setAllValues] = useState({
    email: '',
    passWord: '',
    nation:'HK',
    passWordAlert:'',
    passWordCheckStatus:'',
    chName:'',
    enName:'',
    mobilePhone:'',
    phoneNumberCheckStatus:'',
    zipCode:'',
    address:'',
    createAccountStatus:false,

  });

 
  
  //一般改內容改state用這個
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
    //console.log(allValues)
  }

  //const database=firebase.database()

  //如果不是plasmic創造的元素，都要透過這個方法，才可以丟入額外變數
  const nationChange = (e,{ value }) => {
    setAllValues({ ...allValues, ['nation']: value })
  }
  

  //處理密碼設定的部分
  const passWordHandler = (e) => {
    let passWord=e.target.value
    //千萬注意一件事  set方法要一次寫完，要不然只會執行最後一個，前面的不會動
    //密碼欄只要有動過，那就要重新驗證密碼一致，所以要把passWordCheckStatus歸為false
    if (passWord.length>5){
      if (passWord.match("^[A-Za-z0-9]+$")){
        //通過測試
        setAllValues({ ...allValues, 
                       [e.target.name]: e.target.value,
                       ['passWordAlert']: '',
                       ['passWordCheck']:'',
                       ['passWordCheckStatus']: false})
      }else{
        //含有特別符號
        setAllValues({ ...allValues, 
                      [e.target.name]: e.target.value,
                      ['passWordAlert']: '只接受字母數字，不可有特別符號!', 
                      ['passWordCheck']:'',           
                      ['passWordCheckStatus']: false })
      }
    }else{
        //未達6碼
        setAllValues({ ...allValues, 
                      [e.target.name]: e.target.value,
                      ['passWordAlert']: '至少6碼，只接受字母數字',
                      ['passWordCheck']:'',
                      ['passWordCheckStatus']: false})
    }

  }
  
  //處理密碼二次輸入比對的部分
  const passWordCheckHandler = (e) => {
    let passWord=e.target.value
    //千萬注意一件事  set方法要一次寫完，要不然只會執行最後一個，前面的不會動
    //passWordCheckStatus就是確認密碼輸入兩次是否一致
    if (passWord===allValues.passWord){
        setAllValues({ ...allValues, 
                      ['passWordCheckAlert']: '',
                      [e.target.name]: e.target.value,
                      ['passWordCheckStatus']: true})
    }else{
      //含有特別符號
      setAllValues({ ...allValues, 
                    ['passWordCheckAlert']: '密碼不符合',
                    [e.target.name]: e.target.value})
    }
    
  }
  
  //下面我要做一個ref，那我可以在將來把recaptcha的component清乾淨
  const recaptcha= useRef(0); 

  //發送驗證碼  
  const mobilePhoneCheckHandler= (e) => {
    setAllValues({ ...allValues, 
                   open: true,
                })
    let mobilePhone=allValues.mobilePhone
    
    //電話要加上國碼，才有辦法發簡訊
    //這個步驟我們也會把這支加上國碼的電話號碼鎖定，因為他是被驗證過的，我就可以確認電話沒問題
    if(allValues.nation==='HK'){
            mobilePhone='+852'+ mobilePhone
    }else if(allValues.nation==='MO'){
            mobilePhone='+853'+ mobilePhone
    }else if(allValues.nation==='SG'){
            mobilePhone='+65'+ mobilePhone
    }else if(allValues.nation==='MY'){
           mobilePhone='+60'+ mobilePhone
    }else if(allValues.nation==='TW'){
           mobilePhone='+886'+ mobilePhone.slice(1,)
    }
    
    console.log(mobilePhone)
    
    const appVerifier= new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
    });
 
    firebase.auth().signInWithPhoneNumber(mobilePhone, appVerifier)
        .then((confirmationResult) => {
          setAllValues({ ...allValues, 
                        ['mobilePhoneCheckAlert']: '驗證碼已經送出',
                        ['confirmationResult']:  confirmationResult,
                        ['confirmedmobilePhone']: mobilePhone,
                        open:false,
                      })
          //下面這段是要把recaptcha清乾淨
          recaptcha.current.innerHTML = `<div id="recaptcha-container"></div>`     
        }).catch((error) => {
          setAllValues({ ...allValues, 
                        ['mobilePhoneCheckAlert']: '請確認國家別和電話無誤，再驗證一次，或透過右下方的訊息聯絡我們!',
                        open:false,
                      })
          //console.log(error)
        });
  }

  //確認驗證碼
  const sendCheckNumberHandler= (e) => {
    setAllValues({ ...allValues, 
      open: true,
    })
    let phoneCheckNumber=allValues.phoneCheckNumber
    //根據firebase，我需要剛剛回傳的confirmationResult做驗證
    const confirmationResult = allValues.confirmationResult

    confirmationResult.confirm(phoneCheckNumber).then((result) => {
              setAllValues({ ...allValues, 
                             ['sendCheckNumberAlert']: '手機驗證成功',
                             ['phoneNumberCheckStatus']: true,
                             open:false,
              })
              //我不要手機登錄
              const user = firebase.auth().currentUser;
              user.delete().then(() => {
                // User deleted.
              }).catch((error) => {
                // An error ocurred
                // ...
              });

              //這裡我只是要驗證電話，所以我不要讓user signin
              firebase.auth().signOut()

              
    }).catch((error) => {
              console.log(error)
              setAllValues({ ...allValues, 
                ['sendCheckNumberAlert']: '手機驗證失敗',
              })
    });
  }

  //創建帳號
  //要確認一下email有沒有被註冊過了
  const createAccountHandler=(e) => {
        let email=allValues.email
        let passWord=allValues.passWord
    
        if(allValues.passWordCheckStatus != true){
              setAllValues({ ...allValues, 
                ['createAccountCheckAlert']: '密碼兩次輸入確認不一致',
              })
        }else if(allValues.chName ==='' || allValues.enName===''){
              setAllValues({ ...allValues, 
                ['createAccountCheckAlert']: '中英文名字好像是空白!',
              })
        }else if(allValues.phoneNumberCheckStatus !=true){
              setAllValues({ ...allValues, 
                ['createAccountCheckAlert']: '電話號碼尚未驗證成功!',
              })
        }else if(allValues.zipCode === ''){
              setAllValues({ ...allValues, 
                ['createAccountCheckAlert']: '郵遞區號空白!',
              })
        }else if(allValues.address === ''){
              setAllValues({ ...allValues, 
                ['createAccountCheckAlert']: '地址還沒有填喔!',
              })
        }else{
              //現在進行正式註冊
              firebase.auth().createUserWithEmailAndPassword(email,passWord)
              .then((userCredential) => {
                    // Signed in 
                    var user = userCredential.user;
                    var actionCodeSettings = {
                      // After email is verified, the user will be give the ability to go back
                      // to the sign-in page.
                      url: 'https://gopanda.tw/',
                      handleCodeInApp: false
                    };
                    firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
                    .then(() => {
                      // Email verification sent!
                      // ...
                    });
                    
                    let number=''
                    //我要配發一個會員編號給客人
                    var customerNumberRef = database.child('/infos/customerNumber')  
                    customerNumberRef.transaction((currentData)=> {

                            if(currentData===null){
                              return {'number':0}
                            }else{
                              currentData['number']=Number(currentData['number'])+1
                              return currentData; // 把單號+1，然後存回去
                            }
                          },(error, committed, snapshot)=>{
                            if (error) {
                                
                            } else if (committed) {
                                let currentData=snapshot.val()
                                number=(Number(currentData['number'])+1).toString()              
                                var Space=8-number.length 
                                for (var i = 0; i < Space; i++) {
                                  number ='0' + number;
                                }

                                //會員編號前面加上國碼，以利辨識
                                if(allValues.nation==='HK'){
                                      number='HK'+ number
                                }else if(allValues.nation==='MO'){
                                      number='MO'+ number
                                }else if(allValues.nation==='SG'){
                                      number='SG'+ number
                                }else if(allValues.nation==='MY'){
                                  number='MY'+ number
                                }
                                

                                var updates = {};
                                updates['users/' + user.uid] = {
                                                                  customerNumber:number,
                                                                  email: allValues.email,
                                                                  // uid:user.uid,
                                                                  nation:allValues.nation,
                                                                  chName:allValues.chName,
                                                                  enName:allValues.enName,
                                                                  mobilePhone:allValues.confirmedmobilePhone,
                                                                  zipCode:allValues.zipCode,
                                                                  address1:allValues.address,
                                                                  date: new Date()
                                                                };
                                
                               
                                updates['/infos/customerRef/'+number+'/'] = user.uid;
                                database.update(updates);

                            }  

                          }
                    )
                    // 原本我要把郵件存起來，現在不用了!!auth會做
                    // var emailKey = database.child('/infos/email').push().key;
                    // var updates = {};
                    // updates['/infos/email/' + emailKey] = allValues.email;
                    // database.update(updates)
                    
                    firebase.auth().signOut()

                    setAllValues({ ...allValues, 
                      ['createAccountStatus']: true,
                    })
              })
              .catch((error) => {
                      //電子郵件有沒有被註冊帳號，靠auth就好，我不用記錄了!!
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      if (errorCode === 'auth/email-already-in-use'){
                        setAllValues({ ...allValues, 
                          ['createAccountCheckAlert']: '電郵地址已經被註冊了!',
                        })
                      }
              });
        }

    
    
 
    //做狀況確認
    //密碼沒有問題，每個欄位都有填
    
        // firebase.auth().createUserWithEmailAndPassword(email, passWord)
        // .then((userCredential) => {
        //   // Signed in 
        //   var user = userCredential.user;
        //   // ...
        // })
        // .catch((error) => {
        //   var errorCode = error.code;
        //   var errorMessage = error.message;
        //   // ..
        // });
  }
  

  return (
        <>
            <PlasmicNewMember 
              //下面這句話一定要加，plasmic才這樣把外面的prop設定帶進來
              root={{ ref }} {...props}
              successful={allValues.createAccountStatus}
              //要確認email有沒有被註冊，要另外處理
              email={{
                  name:"email",
                  value: allValues.email,
                  onChange: changeHandler ,
              }}

              passWord={{
                name:"passWord",
                value: allValues.passWord ,
                onChange: passWordHandler,
              }}

            
              passWordAlert={{
                children: allValues.passWordAlert 
              }}
              
              passWordCheck={{
                name:"passWordCheck",
                value: allValues.passWordCheck ,
                onChange: passWordCheckHandler,
              }}

              passWordCheckAlert={ {
                children: allValues.passWordCheckAlert 
              }}

              //下面是決定寄送國家
              nationSelection={{
                render:function MyComponent() {
                  return(
                        <Form  style={{ 'margin-top': '15px' }}>
                            <Form.Group>
                                <Form.Field
                                  control={Radio}
                                  label='香港'
                                  value='HK'
                                  checked={allValues.nation === 'HK'}
                                  onClick={nationChange}
                                  style={{ 'font-size': '20px' ,'font-weight': 'bold'}}
                                />
                                <Form.Field
                                  control={Radio}
                                  label='澳門'
                                  value='MO'
                                  checked={allValues.nation === 'MO'}
                                  onClick={nationChange}
                                  style={{ 'font-size': '20px' ,'font-weight': 'bold' }}
                                />
                                <Form.Field
                                  control={Radio}
                                  label='新加坡'
                                  value='SG'
                                  checked={allValues.nation === 'SG'}
                                  onClick={nationChange}
                                  style={{ 'font-size': '20px' ,'font-weight': 'bold'}}
                                />
                                <Form.Field
                                  control={Radio}
                                  label='馬來西亞'
                                  value='MY'
                                  checked={allValues.nation === 'MY'}
                                  onClick={nationChange}
                                  style={{ 'font-size': '20px' ,'font-weight': 'bold'}}
                                />
                                {/* <Form.Field
                                  control={Radio}
                                  label='台灣'
                                  value='TW'
                                  checked={allValues.nation === 'TW'}
                                  onClick={nationChange}
                                  style={{ 'font-size': '20px' ,'font-weight': 'bold'}}
                                /> */}
                              </Form.Group>
                              
                        </Form>
                )}
              }}
              
              //記得react
              chName={{
                name:"chName",
                value: allValues.chName ,
                onChange: changeHandler,
              }}
              enName={{
                name:"enName",
                value: allValues.enName ,
                onChange: changeHandler,
              }}

              mobilePhone={{
                name:"mobilePhone",
                value: allValues.mobilePhone ,
                onChange: changeHandler,
              }}

              mobilePhoneCheck={{
                name:"mobilePhoneCheck",
                onClick: mobilePhoneCheckHandler,
              }}

              mobilePhoneCheckAlert={ {
                children: allValues. mobilePhoneCheckAlert 
              }}

              phoneCheckNumber={{
                name:"phoneCheckNumber",
                value: allValues.phoneCheckNumber ,
                onChange: changeHandler,
              }}
              recaptcha={{
                ref:(ref) => recaptcha.current = ref
              }}

              sendCheckNumber={{
                name:"sendCheckNumber",
                onClick:  sendCheckNumberHandler,
              }}

              sendCheckNumberAlert={ {
                children: allValues.sendCheckNumberAlert
              }}

              zipCode={{
                name:"zipCode",
                value: allValues.zipCode ,
                onChange: changeHandler,
              }}

              address={{
                name:"address",
                value: allValues.address ,
                onChange: changeHandler,
              }}

              createAccountCheckAlert={ {
                children: allValues.createAccountCheckAlert
              }}

              createAccount={{
                name:"creatAccount",
                onClick: createAccountHandler,
              }}
       
            />
            <Modal
                  basic
                  open={allValues.open}
                  size='small'
                  dimmer='inverted'
            >
                <Loader />   
            </Modal>
        </>



  )
     
}

const NewMember = React.forwardRef(NewMember_);

export default NewMember;

