import React, { useState , useEffect} from 'react';
import { PlasmicDashBoard } from "./plasmic/gopanda/PlasmicDashBoard";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button,Icon,Table,Modal,Header,Input,Form,Container,Divider,Label} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import ShippingItemContent from './ShippingItemContent'
import firebase,{database,firebaseAuth} from './firebase'
//import firebase from "gatsby-plugin-firebase"
import DeletesvgIcon from './plasmic/gopanda/icons/PlasmicIcon__Deletesvg.jsx';
import DollarIcon from './plasmic/gopanda/icons/PlasmicIcon__Dollar.jsx';


function DashBoard_(props, ref) {
  //下面這兩段就是要處理每個欄位的輸入
  const [allValues, setAllValues] = useState({
      itemTotalNumber:1,
      itemArray:[1],     //我要靠這個去決定要放幾個欄位給客人填
      open:false,        //刪除資料的時候，決定畫面的打開
      messageUploadChange:true,
  });

  const uid=props.userUID
  const userInfo=props.userInfo
  //console.log(props.userInfo)
  
  //const database=firebase.database()
  //下面這個寫法為了在UI啟動之前，有一些要先算的東西，都先準備一下
  //下面先把時間帶進來
  //第二參數是設計的關鍵，設一個空的array，就只會mount的時候跑一次，如果不設，就會無限循環一直跑
  //我有透過props把上一層的資料丟進來，所以一啟動的時候，我要把我拿到的資料整理一下
  //不要下載顧客資料，因為上一層已經丟進來
  useEffect(() => {
        var d = new Date();
        let year=d.getFullYear();
        let month=d.getMonth()+1;
        if(month.toString().length===1){
          month="0"+month;
        };
        let day=d.getDate();
        if(day.toString().length===1){
          day="0"+day;
        };
        let Title=year.toString()+month.toString()+day;

        
        setAllValues({ ...allValues,
                      today: Title,
                      year:year,
                      month:month,
                      day:day
                     })

  },[]);
  


  
 
  
  //下面這個寫法就是為了要把資料庫的資料讀出來
  useEffect(() => {
 
    //props傳進來需要時間，所以我要確定一下有資料了，才跑，要不然error
    if( props.userInfo !== null){
      console.log(props.userInfo)
      database.child('/order/'+userInfo.customerNumber+'/'+uid).on('value', snapshot => {
        if(snapshot.val()!==null) {
              let info=snapshot.val()
              let waitList=info.waitList
              let paymentList=info.paymentList
              let readyList=info.readyList
              let finishList=info.finishList
              
              setAllValues(allValues=>({ ...allValues,
                            waitList: waitList,
                            paymentList:paymentList,
                            readyList:readyList,
                            finishList:finishList
                          }))

        }
      });
    }

  }, [database,userInfo,allValues.deleteOrderNumber,allValues.messageUploadChange]);  
     //useeffect真的就是觀察變數變化，沒有變化，它也不會動
     //觀察deleteOrderNumber，是為了刪單以後，會重新查找一下database再讀一次
     //database可以觀察資料庫的變化
    
  
  
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value })
  }

  //增加填寫品項
  const addItemHandler =(e) => {
    
    let number = allValues.itemTotalNumber+1
    let itemArray=[]
    for(let i=1;i<number+1;i++){
      itemArray.push(i)
    }
    setAllValues({ ...allValues, itemTotalNumber: number, itemArray:itemArray })
  }

  //減少填寫品項
  const cutItemHandler =(e) => {
    console.log('cut')
    if(allValues.itemTotalNumber>1){
      let number = allValues.itemTotalNumber-1
      let itemArray=[]
      for(let i=1;i<number+1;i++){
        itemArray.push(i)
      }
      setAllValues({ ...allValues, itemTotalNumber: number, itemArray:itemArray })
    }
   
  }
  

  //改變電商店名
  const storeNamechangeHandler=(e)=>{
          setAllValues({ ...allValues, 
                         storeName: e.target.value,
                         uploadContentCheckAlert:''
                      })
  }   

  //各品項品名改變在用的
  const itemContentChangeHandler=(e)=>{
         setAllValues({ ...allValues, 
                       [e.target.name]: e.target.value,
                       uploadContentCheckAlert:''
                      })
  }
  

  //各品項資料個數和金額改變在用的，因為要特別確認是不是數字
  const  itemContentNumberChangeHandler=(e)=>{
    //https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
    //驗證輸入的字串是不是單純數字
    function isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
             !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    console.log(e.target.value)
    if(isNumeric(e.target.value) || e.target.value===''){
            setAllValues({ ...allValues, 
              [e.target.name]: e.target.value,
              uploadContentCheckAlert:''
            })
    }else{
          setAllValues({ ...allValues, 
            uploadContentCheckAlert:'只能輸入數字喔!!'
          })
    }
    
  }
 
  
  //上傳訂單資料
  const uploadShippingContentHandler=(e)=>{
    //要給每個品項編號，這樣將來好處理
    //另外建立一個暫時的node，暫時記憶目前的單號使用，不要包含在客戶node裡面，方便將來做資料刪除
    //出單前驗證一下網路狀態，確認有存檔，才出單
    if(allValues.storeName===undefined && allValues.itemName1===undefined){
          setAllValues({ ...allValues, 
            uploadContentCheckAlert:'內容不可以空白喔!!'
          })
    }else{
        var deliveryNumberRef = database.child('infos/deliveryNumber/'+allValues.year+'/'+allValues.month+'/'+allValues.day+'/')  
        deliveryNumberRef.transaction((currentData)=> {

                if(currentData===null){
                  return {'number':1}
                }else{
                  currentData['number']=Number(currentData['number'])+1
                  return currentData; // 把品項號增加客人想要加入的品項數目，然後存回去
                }
              },(error, committed, snapshot)=>{
                if (error) {
                    setAllValues({ ...allValues, 
                                  uploadErrorMessage:'請檢查網路狀態，再上傳一次!',  
                                })
                } else if (committed) {
                    //記得要取出item名稱，才可以拿到數字
                    let numberIndex=String(snapshot.val().number)
                    var Space=4-numberIndex.length 
                    for (let j = 0; j < Space; j++) {
                      numberIndex ='0' + numberIndex;
                    }
                    let poContent={}

                    //這裡我已經修正過，我用array的格式做相關的處理
                    let items=[]
                    for(let i=allValues.itemTotalNumber; i>0;i--){
                      let target={}
                      target['itemName']=allValues['itemName'+String(i)]
                      target['itemUnits']=allValues['itemUnits'+String(i)]
                      target['itemAmount']=allValues['itemAmount'+String(i)]    
                      items.push(target)         
                    }

                    poContent['storeName']=allValues.storeName
                    poContent['orderNumber']=allValues.orderNumber
                    // 因為我現在是用array
                    // poContent['itemTotalNumber']=allValues.itemTotalNumber
                    poContent['fillOutTime']=Date()
                    poContent['items']=items
                    poContent['customerNumber']=userInfo.customerNumber
                    poContent['uid']=uid
                    
                    let deliveryNumber='GP'+userInfo.nation+allValues.today.slice(2, 8)+numberIndex
                    database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+deliveryNumber).set(poContent)
                    setAllValues(allValues=>({ ...allValues, 
                                  storeName:'',
                                  orderNumber:'',
                                  itemTotalNumber:1,
                                  itemArray: [1] ,
                                  itemName1:'',
                                  itemUnits1:'',
                                  itemAmount1:'',
                                  uploadContentCheckAlert:'上傳成功'
                                }))

                } 
                
                

              }
        )
    }
    

  }
  
  //當留言input改變的時候，把留言存起來
  //不要用e.target.value，直接呼叫的時候就帶進來
  const messageHandler=(value,key)=>{
    setAllValues({ ...allValues, 
                   ['message'+key]:value,   //這是我們要把message存起來
                })
    
  }

  //key是快遞單號
  const messageUploadHandler=(key)=>{
    database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+key+'/message/').set(allValues['message'+key])
    database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+key+'/messageHistory/').once('value', snapshot => {
              if(snapshot.val()==null) {
                  let messageHistory=allValues['message'+key]
                  database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+key+'/messageHistory/').set(messageHistory)
              }else{
                  let messageHistory=snapshot.val()+';'+allValues['message'+key]
                  database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+key+'/messageHistory/').set(messageHistory)
              }
    })
    setAllValues({ ...allValues,
                   ['message'+key]:'',    
                   messageUploadChange:! allValues.messageUploadChange  //這句話是要讓useeffect運作更新一下
                })
    
  }
  
  //點刪除的時候，先把準備刪的資料存起來
  const deletePrepare=(key,orderNumber)=>{
    setAllValues(allValues=>({ ...allValues, 
                  deleteOrderKey: key,
                  deleteOrderNumber:orderNumber,
                  open:true
                }))

  }

  //確定刪除的時候，就真的對database動手
  const deleteItem=()=>{
    database.child('/order/'+userInfo.customerNumber+'/'+uid+'/waitList/'+allValues.deleteOrderKey).set({});

    setAllValues({ ...allValues, 
                   deleteOrderNumber:'',
                   open:false
                })
  }


  //付款囉!!
  const pay=(order)=>{
    //下面就會開啟連結，開始付款
    //let url='http://localhost:5001/gopanda-b26fa/us-central1/ecpay-process?'+'customerNumber='+props.userInfo['customerNumber']+'&poNumber='+order
    let url='https://us-central1-gopanda-b26fa.cloudfunctions.net/ecpay-process?'+'customerNumber='+props.userInfo['customerNumber']+'&poNumber='+order
    window.open(url, "_blank")
    
  }


  if (props.userInfo){
    return <PlasmicDashBoard 
              root={{ ref }} {...props} 
              nameCopy={{
              render:function MyComponent() {
                return(
                <CopyToClipboard
                  //options={{ debug: props.debug, message: "" }}
                  text={'冠運物流-#JF'+props.userInfo['customerNumber']}
                  onCopy={() =>  setAllValues({ ...allValues, 
                                                'nameCopied': true,
                                                'phoneCopied': false,
                                                'addressCopied': false,
                                                'zipCodeCopied': false,
                                                'cityCopied': false,
                                                'areaCopied': false,
                                              })}
                >
                  <Button size='mini' color='red'>點我複製</Button>
                </CopyToClipboard>
              )}
              }}

              nameCopyAlert={{
                children: allValues.nameCopied ? 'o.k.' : null 
              }}
              nameCol={{
                children:props.userInfo ? '冠運物流-#JF'+props.userInfo['customerNumber'] : '冠運物流-#'
              }}

              phoneCopy={{
                  render:function MyComponent() {
                    return(
                    <CopyToClipboard
                      //options={{ debug: props.debug, message: "" }}
                      text={'0919873577'}
                      onCopy={() =>  setAllValues({ ...allValues, 
                                                    'nameCopied': false,
                                                    'phoneCopied': true,
                                                    'addressCopied': false,
                                                    'zipCodeCopied': false,
                                                    'cityCopied': false,
                                                    'areaCopied': false,
                                                  })}
                    >
                      <Button size='mini' color='red'>點我複製</Button>
                    </CopyToClipboard>
                  )}
              }}

              phoneCopyAlert={{
                children: allValues.phoneCopied ? 'o.k.' : null 
              }}

              addressCopy={{
                render:function MyComponent() {
                  return(
                  <CopyToClipboard
                    //options={{ debug: props.debug, message: "" }}
                    text={'桃園市龜山區忠義路三段231巷33之2號'}
                    onCopy={() =>  setAllValues({ ...allValues, 
                                                  'nameCopied': false,
                                                  'phoneCopied': false,
                                                  'addressCopied': true,
                                                  'zipCodeCopied': false,
                                                  'cityCopied': false,
                                                  'areaCopied': false, 
                                                })}
                  >
                    <Button size='mini' color='red'>點我複製</Button>
                  </CopyToClipboard>
                )}
              }}

              addressCopyAlert={{
                children: allValues.addressCopied ? 'o.k.' : null 
              }}

              zipCodeCopy={{
                render:function MyComponent() {
                  return(
                  <CopyToClipboard
                    //options={{ debug: props.debug, message: "" }}
                    text={'33373'}
                    onCopy={() =>  setAllValues({ ...allValues, 
                                                'nameCopied': false,
                                                'phoneCopied': false,
                                                'addressCopied': false,
                                                'zipCodeCopied': true,
                                                'cityCopied': false,
                                                'areaCopied': false, 
                                              })}
                  >
                    <Button size='mini' color='red'>點我複製</Button>
                  </CopyToClipboard>
                )}
              }}

              zipCodeCopyAlert={{
                children: allValues.zipCodeCopied ? 'o.k.' : null 
              }}

              cityCopy={{
                render:function MyComponent() {
                  return(
                  <CopyToClipboard
                    //options={{ debug: props.debug, message: "" }}
                    text={'桃園市'}
                    onCopy={() =>  setAllValues({ ...allValues, 
                                                'nameCopied': false,
                                                'phoneCopied': false,
                                                'addressCopied': false,
                                                'zipCodeCopied': false,
                                                'cityCopied': true,
                                                'areaCopied': false,
                                              })}
                  >
                    <Button size='mini' color='red'>點我複製</Button>
                  </CopyToClipboard>
                )}
              }}

              cityCopyAlert={{
                children: allValues.cityCopied ? 'o.k.' : null 
              }}

              areaCopy={{
                render:function MyComponent() {
                  return(
                  <CopyToClipboard
                    //options={{ debug: props.debug, message: "" }}
                    text={'龜山區'}
                    onCopy={() =>  setAllValues({ ...allValues, 
                                                  'nameCopied': false,
                                                  'phoneCopied': false,
                                                  'addressCopied': false,
                                                  'zipCodeCopied': false,
                                                  'cityCopied': false,
                                                  'areaCopied': true, 
                                                })}
                  >
                    <Button size='mini' color='red'>點我複製</Button>
                  </CopyToClipboard>
                )}
              }}

              areaCopyAlert={{
                children: allValues.areaCopied ? 'o.k.' : null 
              }}
              
              storeName={{
                    name:"storeName",
                    value: allValues.storeName,
                    onChange: storeNamechangeHandler ,
              }}
              orderNumber={{
                name:"orderNumber",
                value: allValues.orderNumber,
                onChange: changeHandler,
              }}

              storeNameDesktop={{
                name:"storeName",
                value: allValues.storeName,
                onChange: storeNamechangeHandler ,
              }}
              orderNumberDesktop={{
                name:"orderNumber",
                value: allValues.orderNumber,
                onChange: changeHandler,
              }}

              addShippingItem={{
                onClick:addItemHandler
              }}

              cutShippingItem={{
                onClick:cutItemHandler
              }}

              addShippingItemDesktop={{
                onClick:addItemHandler
              }}

              cutShippingItemDesktop={{
                onClick:cutItemHandler
              }}
              
              //這是寄送內容輸入的部分
              shippingContentArea={{
                children:allValues.itemArray.map((item) => (
                
                  <ShippingItemContent
                        key={item}
                        itemIndex={{
                              children:item
                        }}  
                        
                        itemName={{
                          value:allValues['itemName'+item], 
                          name:'itemName'+item,
                          onChange:itemContentChangeHandler
                        }}

                        itemUnits={{
                          value:allValues['itemUnits'+item], 
                          name:'itemUnits'+item,
                          onChange:itemContentNumberChangeHandler
                        }}

                        itemAmount={{
                          value:allValues['itemAmount'+item], 
                          name:'itemAmount'+item,
                          onChange:itemContentNumberChangeHandler
                        }}
                  
                  />
                ))
              }}
              
              //這是寄送內容輸入的部分
              shippingContentAreaDesktop={{
                children:allValues.itemArray.map((item) => (
                
                  <ShippingItemContent
                        key={item}
                        itemIndex={{
                              children:item
                        }}  
                        
                        itemName={{
                          value:allValues['itemName'+item], 
                          name:'itemName'+item,
                          onChange:itemContentChangeHandler
                        }}

                        itemUnits={{
                          value:allValues['itemUnits'+item], 
                          name:'itemUnits'+item,
                          onChange:itemContentNumberChangeHandler
                        }}

                        itemAmount={{
                          value:allValues['itemAmount'+item], 
                          name:'itemAmount'+item,
                          onChange:itemContentNumberChangeHandler
                        }}
                  
                  />
                ))
              }}
              uploadShippingContent={{
                onClick:uploadShippingContentHandler
              }}

              uploadShippingContentDesktop={{
                onClick:uploadShippingContentHandler
              }}

              uploadContentCheckAlert={{
                children: allValues.uploadContentCheckAlert 
              }}

              uploadContentCheckAlertDesktop={{
                children: allValues.uploadContentCheckAlert 
              }}

              waitList={{
                render: function MyComponent() {
                  return(
                  allValues.waitList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                       
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#05b2dc',width:'15%' }}>訂單資訊</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#05b2dc',width:'55%' }}>備註訊息(訂單的相關訊息，都可以這裡填寫)</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#05b2dc',width:'15%' }}>刪除</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.waitList).map((order,i)=>{
                              let orders =allValues.waitList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#bee9e8' 
                                }else{
                                    color='#fedfd4'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          
                                          <Table.Cell width='6' style={{ fontSize: '12px', 'font-weight':'bold'  }}>
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '14px' }}>
                                            <Form>
                                              <Form.Field>
                                                    {orders[order].message? orders[order].message:''}
                                              </Form.Field>
                                              <Form.Field>
                                                    <Input size='small' placeholder='留言' fluid value={allValues['message'+order]}  style={{ height: '25px','margin-right':'10px' }} onChange={(e)=>messageHandler(e.target.value,order)}/>  
                                              </Form.Field>
                                              <Form.Field>
                                                    <Button size='mini' type='submit'  style={{ height: '25px','backgroundColor':'#16D145', }} onClick={()=>messageUploadHandler(order)}>送出</Button>       
                                              </Form.Field>
                                            </Form>

                                          </Table.Cell>
                                          
                                          <Table.Cell>
                                                  <Button icon size='mini' color='red' onClick={()=>deletePrepare(order,orders[order].orderNumber)}>
                                                    {/* <Icon name='delete' /> */}
                                                    <DeletesvgIcon style={{ 'color':'white',"font-size":"16px" }}/>
                                                  </Button>
                                          </Table.Cell>
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                        </Modal>
                    </Table.Body>
                  </Table>
                  :
                  
                  <Container textAlign='center'> 
                          <Header as='h4'>目前沒有訂單</Header>
                  </Container>
                )}
              }}
              
              //要注意只能接受camel寫法，不能全大寫
              waitListDesktop={{
                render: function Component() {
                  return(
                  allValues.waitList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#05b2dc',width:'15%' }}>店名</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#05b2dc',width:'15%' }}>訂單編號</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#05b2dc',width:'55%' }}>備註訊息(訂單的相關訊息，都可以這裡填寫)</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#05b2dc',width:'15%' }}>刪除</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '22px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.waitList).map((order,i)=>{
                              let orders =allValues.waitList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#bee9e8' 
                                }else{
                                    color='#fedfd4'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell style={{ fontSize: '16px', 'font-weight':'bold'  }}>{orders[order].storeName}</Table.Cell>
                                          <Table.Cell style={{ fontSize: '16px', 'font-weight':'bold'  }}>{orders[order].orderNumber}</Table.Cell>
                                          <Table.Cell style={{ fontSize: '16px' }}>
                                            <Form>
                                              <Form.Field>
                                                    {orders[order].message? orders[order].message:''}
                                              </Form.Field>
                                              <Form.Field>
                                                    <Input size='small' placeholder='留言' fluid value={allValues['message'+order]}  style={{ height: '35px','margin-right':'10px',fontSize: '16px' }} onChange={(e)=>messageHandler(e.target.value,order)}/>  
                                              </Form.Field>
                                              <Form.Field>
                                                    <Button size='mini' type='submit'  style={{ height: '35px','backgroundColor':'#16D145',fontSize: '16px' }} onClick={()=>messageUploadHandler(order)}>送出</Button>       
                                              </Form.Field>
                                            </Form>

                                          </Table.Cell>
                                          
                                          <Table.Cell>
                                                  <Button icon size='mini' color='red' onClick={()=>deletePrepare(order,orders[order].orderNumber)}>
                                                    {/* <Icon name='delete' /> */}
                                                    <DeletesvgIcon style={{ 'color':'white',"font-size":"16px" }}/>
                                                  </Button>
                                          </Table.Cell>
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                    </Table.Body>
                  </Table>
                  :
                  
                  <Container textAlign='center'> 
                          <Header as='h4'>目前沒有訂單</Header>
                  </Container>
                )}
              }}
            

              paymentList={{
                render: function MyComponent() {
                  return (
                  allValues.paymentList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#ff7b00',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#ff7b00',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#ff7b00',width:'30%' }}>支付</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.paymentList).map((order,i)=>{
                              let orders =allValues.paymentList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#ffd500' 
                                }else{
                                    color='#f9f26b'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '14px', 'font-weight':'bold'  }}>
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                              <Divider />
                                              寄送地址:<br/>{userInfo.address1}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '14px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                          {/* 這是藍新付款的介面
                                          <Table.Cell  width='5'>
                                                  <form name="Newebpay" action="https://ccore.newebpay.com/MPG/mpg_gateway" method="POST" > 
                                
                                                    <input type="hidden" name="MerchantID" value={orders[order].newebpay.MerchantID}/>
                                                    <input type="hidden" name="TradeInfo" value={orders[order].newebpay.TradeInfo}/>
                                                    <input type="hidden" name="TradeSha" value={orders[order].newebpay.TradeSha}/>
                                                    <input type="hidden" name="Version" value={orders[order].newebpay.Version}/>
                                                    

                                                    <Button icon size='mini' color='red'  type="submit">
                                                        <DollarIcon  style={{ "font-size":"16px" }}/>
                                                    </Button>
                                                  </form>       
                                          </Table.Cell> */}
                                          
                                          {/* 下面這是綠界的付款格式 */}
                                          <Table.Cell  width='5'> 
                                                    <Button icon size='mini' color='red'   onClick={() => pay(order)}>
                                                        <DollarIcon  style={{ "font-size":"16px" }}/>
                                                    </Button>      
                                          </Table.Cell>
                                      </Table.Row>

                          }
                              
                        )}
                        
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}

              paymentListDesktop={{
                render: function MyComponent() {
                  return (
                  allValues.paymentList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ff7b00',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ff7b00',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ff7b00',width:'30%' }}>支付</Table.HeaderCell>

                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.paymentList).map((order,i)=>{
                              let orders =allValues.paymentList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#ffd500' 
                                }else{
                                    color='#f9f26b'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '16px', 'font-weight':'bold'  }}>
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                              <Divider />
                                              寄送地址:<br/>{userInfo.address1}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '16px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                          {/* 這是藍新付款的介面
                                          <Table.Cell  width='5'>
                                                  <form name="Newebpay" action="https://ccore.newebpay.com/MPG/mpg_gateway" method="POST" > 
                                
                                                    <input type="hidden" name="MerchantID" value={orders[order].newebpay.MerchantID}/>
                                                    <input type="hidden" name="TradeInfo" value={orders[order].newebpay.TradeInfo}/>
                                                    <input type="hidden" name="TradeSha" value={orders[order].newebpay.TradeSha}/>
                                                    <input type="hidden" name="Version" value={orders[order].newebpay.Version}/>
                                                    
                                                    <Button icon size='mini' color='red'  type="submit">
                                                        <DollarIcon  style={{ "font-size":"16px" }}/>
                                                    </Button>
                                                  </form>
                                                  
                                          </Table.Cell> */}

                                          {/* 下面這是綠界的付款格式 */}
                                          <Table.Cell  width='5'> 
                                                    <Button icon size='mini' color='red'   onClick={() => pay(order)}>
                                                        <DollarIcon  style={{ "font-size":"16px" }}/>
                                                    </Button>      
                                          </Table.Cell>
                                      </Table.Row>

                          }
                              
                        )}
                        
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}

              

              readyList={{
                render: function MyComponent() {
                  return (
                  allValues.readyList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#ff4d6d',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#ff4d6d',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.readyList).map((order,i)=>{
                              let orders =allValues.readyList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#ffccd5' 
                                }else{
                                    color='#fff0f3'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '14px', 'font-weight':'bold'  }} >
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '14px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}


              readyListDesktop={{
                render: function MyComponent() {
                  return (
                  allValues.readyList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ff4d6d',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#ff4d6d',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.readyList).map((order,i)=>{
                              let orders =allValues.readyList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#ffccd5' 
                                }else{
                                    color='#fff0f3'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '16px', 'font-weight':'bold'  }}>
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '16px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}

              finishList={{
                render: function MyComponent() {
                  return (
                  allValues.finishList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#80b918',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '12px', 'backgroundColor': '#80b918',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.readyList).map((order,i)=>{
                              let orders =allValues.readyList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                  color='#b5e48c' 
                              }else{
                                  color='#d9ed92'
                              } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '14px', 'font-weight':'bold'  }} >
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '14px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}


              finishListDesktop={{
                render: function MyComponent() {
                  return (
                  allValues.finishList ?
                  <Table striped unstackable style={{ width: '100%', 'backgroundColor': '#ffdd00' ,'textAlign': 'center' }}>
                    <Table.Header >

                      <Table.Row >
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#80b918',width:'40%' }}>訂單</Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: 'bold', fontSize: '16px', 'backgroundColor': '#80b918',width:'30%' }}>重量及運費</Table.HeaderCell>
                        
                      </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ fontSize: '12px' }}>
                        {/* 下面這是最重要的一段，靠這段，我要改掉用state的習慣*/}
                        
                        { Object.keys(allValues.readyList).map((order,i)=>{
                              let orders =allValues.readyList
                              //我希望間隔顏色，提高辨識度
                              let color=''
                              if( i%2 === 0){
                                    color='#b5e48c' 
                                }else{
                                    color='#d9ed92'
                                } 
                              return <Table.Row  key={i} style={{ 'background-color': color }}>
                                          <Table.Cell width='6' style={{ fontSize: '16px', 'font-weight':'bold'  }}>
                                              店名:{orders[order].storeName}<br/>
                                              訂單號:{orders[order].orderNumber}<br/>
                                              <Divider />
                                              快遞號:<br/>{order}
                                          </Table.Cell>
                                          <Table.Cell style={{ fontSize: '16px', 'font-weight':'bold'  }} width='5'>
                                              重量:<br/>{orders[order].weight? orders[order].weight+' KGs':''}<br/>
                                              運費NT: <br/> {orders[order].fee? orders[order].fee :''}
                                          </Table.Cell>
                                          
                                          
                                      </Table.Row>

                          }
                              
                        )}
                        <Modal
                              basic
                              // onClose={() => setOpen(false)}
                              // onOpen={() => setOpen(true)}
                              open={allValues.open}
                              size='small'
                              // trigger={ }
                            >
                              <Header >
                                確定刪除 {allValues.deleteOrderNumber} ??
                              </Header>
                              
                              <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setAllValues({ ...allValues, open:false })}>
                                  <Icon name='remove' /> No
                                </Button>
                                <Button color='green' inverted onClick={() => deleteItem()}>
                                  <Icon name='checkmark' /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                    </Table.Body>
                  </Table>
                  :
                  <></>
                )}
              }}

              changeCustomerInfo={{
                render:function MyComponent() {
                  return(
                    
                        <a href="/changeinfo" target="_blank" >  
                            <Label as='h1'  color='red'size='big' textAlign='center'>
                                    <div style={{ color: 'white'}}>更改會員預設資料去!!</div>                       
                            </Label>
                            
                        </a>
                  )
                }
              }}

              
    
          />;
  }else{
    return <></>
  }
  
}

const DashBoard = React.forwardRef(DashBoard_);

export default DashBoard;
