// extracted by mini-css-extract-plugin
export var root = "PlasmicIndex-module--root--3Jg1Y";
export var freeBox__zprgI = "PlasmicIndex-module--freeBox__zprgI--2mJye";
export var box = "PlasmicIndex-module--box--2YWqC";
export var header = "PlasmicIndex-module--header--2pd9K";
export var freeBox__srfGl = "PlasmicIndex-module--freeBox__srfGl--1ti01";
export var freeBox___0T2Cv = "PlasmicIndex-module--freeBox___0T2Cv--Pilet";
export var img__fUqcE = "PlasmicIndex-module--img__fUqcE--c9heJ";
export var __wab_imgSpacer = "PlasmicIndex-module--__wab_img-spacer--2eZo_";
export var text__uloif = "PlasmicIndex-module--text__uloif--2EHxq";
export var freeBox__p4Wt = "PlasmicIndex-module--freeBox__p4Wt--2B9N1";
export var text___9Vsna = "PlasmicIndex-module--text___9Vsna--yttZW";
export var text__mkSax = "PlasmicIndex-module--text__mkSax--1JpZt";
export var flight = "PlasmicIndex-module--flight--2c2bk";
export var freeBox__vp6Yx = "PlasmicIndex-module--freeBox__vp6Yx--3Csqm";
export var freeBox__xgy3 = "PlasmicIndex-module--freeBox__xgy3--v7VJu";
export var text__cu5T0 = "PlasmicIndex-module--text__cu5T0--3BT8m";
export var text__uQxmt = "PlasmicIndex-module--text__uQxmt--1jHcE";
export var freeBox__gpX4 = "PlasmicIndex-module--freeBox__gpX4--2vt1s";
export var svg___2Q6Ko = "PlasmicIndex-module--svg___2Q6Ko--2B3JN";
export var gift = "PlasmicIndex-module--gift--2Bntz";
export var freeBox__mxANp = "PlasmicIndex-module--freeBox__mxANp--2AAVd";
export var svg__jU9Ny = "PlasmicIndex-module--svg__jU9Ny--2KJ65";
export var freeBox__rUtF = "PlasmicIndex-module--freeBox__rUtF--1IAr0";
export var freeBox__jtUhp = "PlasmicIndex-module--freeBox__jtUhp--1jIFo";
export var text__mlxg1 = "PlasmicIndex-module--text__mlxg1--1kM-g";
export var text__ltM1K = "PlasmicIndex-module--text__ltM1K--2O3sm";
export var step = "PlasmicIndex-module--step--loSFp";
export var freeBox__nUKau = "PlasmicIndex-module--freeBox__nUKau--27JLV";
export var freeBox__qBnQv = "PlasmicIndex-module--freeBox__qBnQv--tEzU8";
export var howToPrice = "PlasmicIndex-module--howToPrice---TP-Q";
export var freeBox__xItDb = "PlasmicIndex-module--freeBox__xItDb--1wYew";
export var howToProcess = "PlasmicIndex-module--howToProcess--2lp3m";
export var freeBox__fDaEd = "PlasmicIndex-module--freeBox__fDaEd--2ulge";
export var freeBox__qnTIq = "PlasmicIndex-module--freeBox__qnTIq--aaXut";
export var contract = "PlasmicIndex-module--contract--3JVil";
export var freeBox__moR92 = "PlasmicIndex-module--freeBox__moR92--3jnvg";
export var notAllowToDelivery = "PlasmicIndex-module--notAllowToDelivery--_mbK_";
export var joinMember = "PlasmicIndex-module--joinMember--1fiHs";
export var newMember = "PlasmicIndex-module--newMember--2y3YF";
export var loginForm = "PlasmicIndex-module--loginForm--2nFCG";
export var dashBoard = "PlasmicIndex-module--dashBoard--3MMJv";
export var gopandaInfo = "PlasmicIndex-module--gopandaInfo--6guon";
export var freeBox__dBwnq = "PlasmicIndex-module--freeBox__dBwnq--m2kvR";
export var freeBox__jlPom = "PlasmicIndex-module--freeBox__jlPom--3Uy8t";
export var img__qx0JQ = "PlasmicIndex-module--img__qx0JQ--RFnQP";
export var freeBox___3L5En = "PlasmicIndex-module--freeBox___3L5En--3kG64";
export var freeBox__sJYpc = "PlasmicIndex-module--freeBox__sJYpc--2-SMa";
export var text__aoudN = "PlasmicIndex-module--text__aoudN--2O8H8";
export var text__s3Xt = "PlasmicIndex-module--text__s3Xt--3vCW3";
export var mail = "PlasmicIndex-module--mail--1md5Y";
export var freeBox__iSzx = "PlasmicIndex-module--freeBox__iSzx--2I17V";
export var text__jwpmL = "PlasmicIndex-module--text__jwpmL--35gES";
export var text__qSzQ = "PlasmicIndex-module--text__qSzQ--34kH1";
export var phone = "PlasmicIndex-module--phone--1-3H-";
export var freeBox__fRq9D = "PlasmicIndex-module--freeBox__fRq9D--AmYGH";
export var text___6OdXb = "PlasmicIndex-module--text___6OdXb--3YRLP";
export var text__qmHKp = "PlasmicIndex-module--text__qmHKp--3HVa9";
export var fb = "PlasmicIndex-module--fb--3MSAH";
export var freeBox__d7Rqx = "PlasmicIndex-module--freeBox__d7Rqx--13rDw";
export var text___3QBw = "PlasmicIndex-module--text___3QBw--cXoeI";
export var facebook = "PlasmicIndex-module--facebook--2QbVz";
export var whatApp = "PlasmicIndex-module--whatApp--1Sr3q";
export var freeBox__wga9U = "PlasmicIndex-module--freeBox__wga9U--3IQ21";
export var text__ohklg = "PlasmicIndex-module--text__ohklg--HCg0R";
export var img__dDXml = "PlasmicIndex-module--img__dDXml--3l70b";
export var 倉庫照片 = "PlasmicIndex-module--倉庫照片--359AF";
export var freeBox__hhhah = "PlasmicIndex-module--freeBox__hhhah--1EJEw";
export var text___7LpGy = "PlasmicIndex-module--text___7LpGy--2PBa7";
export var img__oBrvV = "PlasmicIndex-module--img__oBrvV--1nULw";
export var freeBox__bHjaC = "PlasmicIndex-module--freeBox__bHjaC--3d4re";
export var freeBox__auREz = "PlasmicIndex-module--freeBox__auREz--3QPQU";
export var text__rlXll = "PlasmicIndex-module--text__rlXll--2Y-Kw";