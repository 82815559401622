      import React, {useEffect} from 'react';
     
      function CustomerChat(){
        useEffect(()=>{
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "187413390013269");
            chatbox.setAttribute("attribution", "biz_inbox");
      
            window.fbAsyncInit = function() {
              window.FB.init({
                xfbml            : true,
                version          : 'v12.0'
              });
            };
      
            (function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        })
        return(
          <>
             <div id="fb-root"></div>

            <div id="fb-customer-chat" class="fb-customerchat">
            </div>
          </>
        )
      }

      export default CustomerChat;